import {mainMenu} from '@app/menu';
import {MediaObserver} from '@angular/flex-layout';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '@app/modules/pages/login/auth.service';
import {CoreConfigService} from '@core/services/config.service';
import {CoreMediaService} from '@core/services/media.service';
import {Router} from '@angular/router';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {BaseComponent} from '@app/common/classes/base.component';
import {CoreCommonModule} from '@core/common.module';
import {LocalStorageService} from '@core/services/storages/local-storage.service';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {CoreConfig, CoreMenu} from '@core/types';
import {CoreMenuComponent} from '@core/components/core-menu/core-menu.component';
import {CoreMenuService} from '@core/components/core-menu/core-menu.service';
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {Component, HostBinding, HostListener, OnDestroy, OnInit, ViewEncapsulation,} from '@angular/core';
import {NavbarNotificationComponent} from '@core/layout/components/navbar/navbar-notification/navbar-notification.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CoreCommonModule,
    KeenIconComponent,
    NavbarNotificationComponent,
    NgbDropdownModule,
    CoreMenuComponent,
  ],
})

export class NavbarComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  horizontalMenu: boolean;
  hiddenMenu: boolean;
  coreConfig: CoreConfig;
  currentSkin: string;
  prevSkin: string;
  currentUser: any;
  isDropdownOpen = false;
  languageOptions: any;
  layoutOptions: any;
  navigation: any;
  selectedLanguage: any;
  selectedLayout: any;
  activeModulePath: string;
  resultPath: string;
  avatar: any;
  modulePath = ['crm', 'call-center', 'service-desk', 'chat-service'];
  avatarValid: boolean;

  localStorageValue: any;

  @HostBinding('class.fixed-top')
  isFixed = false;

  protected menu = mainMenu;

  // _usersBlockHistory=inject(UsersBlockHistoryService)
  constructor(
    private _router: Router,
    private _authenticationService: AuthService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreMenuService: CoreMenuService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    private _localStorageService: LocalStorageService,
  ) {
    super();
    this._authenticationService.currentUser.subscribe(
      (res: any) => {
        this.avatar = res?.thumbnail;

        this.currentUser = res;

        if (this.avatar) {
          this.checkImageUrl(this.avatar).then((isValid) => {
            this.avatarValid = isValid;
          });
        } else {
          this.avatarValid = false;
        }
      }
    );
    this.layoutOptions = [
      {
        title: 'ChangeMenu.Mega_Menu',
        key: 'mega',
        icon: 'row-horizontal',
      },
      {
        title: 'ChangeMenu.Dropdown_Menu',
        key: 'horizontal',
        icon: 'element-9',
      },
    ];

    this.languageOptions = {
      uz: {
        title: 'O`zbek tili',
        flag: 'uz',
        src: '/assets/flags/uz.svg'
      },
      crl: {
        title: 'Ўзбек тили',
        flag: 'uz',
        src: '/assets/flags/uz.svg'
      },
      ru: {
        title: 'Русский язык',
        flag: 'ru',
        src: '/assets/flags/ru.svg'
      },
      kk: {
        title: 'Qaraqalpaq tili',
        flag: 'uz',
        src: '/assets/flags/uz.svg'
      },
    };

    this.activeModulePath = window.location.pathname.split('/')[1];
    this.resultPath = this.modulePath.includes(this.activeModulePath)
      ? this.activeModulePath
      : this.modulePath[0];
  }

  @HostListener('document:click', ['$event'])
  closeDropdownOnClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.dropdown')) {
      this.isDropdownOpen = false;
    }
  }

  menuChanged(id: string) {
    this._localStorageService.set('rememberMenu', id);
    this._coreMenuService.setCurrentMenu(id);

    this.closeDropdown();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  closeDropdown() {
    this.isDropdownOpen = false;
  }

  toggleSidebar(key: string): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  refreshPage() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.navigate([this._router.url], {skipLocationChange: true});
  }

  setLanguage(language: string): void {
    this.selectedLanguage = language;
    this._translateService.use(language);
    this._coreConfigService.setConfig(
      {app: {appLanguage: language}},
      {emitEvent: true}
    );
    location.reload();
  }

  setLayout(layout: string): void {
    this.selectedLayout = layout;
    this._coreConfigService.setConfig(
      {layout: {type: layout}},
      {emitEvent: true}
    );
    location.reload();
  }

  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        {layout: {skin: this.prevSkin ? this.prevSkin : 'default'}},
        {emitEvent: true}
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig(
        {layout: {skin: 'dark'}},
        {emitEvent: true}
      );
    }
  }

  logout() {
    // this._usersBlockHistory.insert({status:Block.Blocked}).subscribe()
    this._authenticationService.logout();
    this._router.navigate(['login']);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.getLocalStorag();

    // Subscribe to the config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: CoreConfig) => {
        this.coreConfig = config;
        this.horizontalMenu = config.layout.type === 'horizontal';
        this.hiddenMenu = config.layout.menu.hidden === true;
        this.currentSkin = config.layout.skin;

        // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
        // if (this.coreConfig.layout.type === 'vertical') {
        //   setTimeout(() => {
        //     if (this.coreConfig.layout.navbar.type === 'fixed-top') {
        //       this.isFixed = true;
        //     }
        //   }, 0);
        // }
      });

    if (this.coreConfig.layout.type === 'horizontal') {
      this._coreMediaService.onMediaUpdate
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
          this.isFixed = !isFixedTop;
        });
    }

    const currentUserRole = this.currentUser?.roles[0]?.name;

    this.menu = mainMenu.filter((menuItem: CoreMenu) => {
      return menuItem.role.includes(currentUserRole);
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getLocalStorag() {
    this.localStorageValue =
      this._localStorageService.get('config')?.layout?.type;
  }


  navigateFunction(): string {
    const currentPath = this.router.url.split('/')[1];
    return `/${currentPath}`;
  }

  navigateToChat() {
    this.router.navigate(['corporate-chat']);
  }

  checkImageUrl(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  }
}
