import {Component, inject, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {OutgoingCallService} from '../../services/outgoing-call.service';
import {BasicForm} from '@app/shared/components/forms/basic/basic.form';
import {FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';
import {UpsPhoneInputComponent} from '@shared/components/forms/components/ups-phone-input/ups-phone-input.component';

@Component({
  selector: 'outgoing-call',
  templateUrl: './outgoing-call.component.html',
  styleUrl: './outgoing-call.component.scss',
  standalone: true,
  imports: [
    ButtonComponent,
    ReactiveFormsModule,
    UpsPhoneInputComponent
  ],
})
export class OutgoingCallComponent extends BasicForm implements OnInit {

  _defaultService = inject(OutgoingCallService);

  form: FormGroup = this._fb.group({
    phone_number: [null, Validators.required],
  });

  outgoingForm: FormGroup = this._fb.group({
    phone_number: [null, Validators.required],
  });

  ngOnInit(): void {
    super.ngOnInit();
  }

  override onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid && !this.isPending()) {
      this.beforeSubmit();

      this.outgoingForm.get('phone_number').patchValue('+998' + this.form.get('phone_number').value);
      const data = this.convertToRequestData(this.outgoingForm.value);

      this.submitAction(data)
        .subscribe({
          next: (res) => this.afterSuccessSubmit(res),
          error: (res: HttpErrorResponse) => this.afterErrorSubmit(res),
        });

    }
  }

}
