import {Component, inject, OnInit, signal} from '@angular/core';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';
import {RouterLink} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {SafePathPipe} from '@shared/pipes/safe-path.pipe';
import {BASE_URL, CHAT_URL, LANG_RU} from '@core/services/api.service';
import {tap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {finalize} from 'rxjs/operators';
import {TranslateModule} from '@ngx-translate/core';
import {SpinnerComponent} from '@shared/components/spinner/spinner.component';
import {AuthService} from '../../../pages/login/auth.service';
import {LiveCallsDataService} from '@app/common/customer-evaluation/live-calls-data.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat-main.component.html',
  styleUrls: ['./chat-main.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    RouterLink,
    SafePathPipe,
    TranslateModule,
    SpinnerComponent
  ]
})
export class ChatMainComponent implements OnInit {
  public environment = environment;
  protected chatAutoLoginUrl: string;
  protected isChatLoading = signal(false);
  protected isHidden = signal(true);
  private http = inject(HttpClient);
  private currentUser = signal(null);

  constructor(private _authenticationService: AuthService, private _liceCallsDataService: LiveCallsDataService) {
    this._authenticationService.currentUser.subscribe(
      (x) => (this.currentUser.set(x))
    );

    if (JSON.parse(localStorage.getItem('rememberMenu')) == 'callCenter') {
      this._liceCallsDataService.sendVisibleData(true);
    } else {
      this._liceCallsDataService.sendVisibleData(false);
    }
  }

  ngOnInit() {
    const profile = this.currentUser();

    if (profile) {
      this.loginAutoToChat(profile);
    }
  }

  loginAutoToChat(profile: any) {
    this.isChatLoading.set(true);

    const lang = localStorage.getItem('config');

    const parsedLang = JSON.parse(lang)?.app?.appLanguage ? JSON.parse(lang).app.appLanguage : LANG_RU;

    this.http.post(`${BASE_URL}/chat-api/api/v1/user/token`, {
      email: profile.email,
      uuid: profile.id,
      name: profile.name,
    }).pipe(tap((res: any) => {
        this.chatAutoLoginUrl = `${CHAT_URL}/token.html?token=${encodeURIComponent(res.data.token)}&expires=${res.data.expires}&lang=${parsedLang}`;
      }),
      finalize(() => {
        setTimeout(() => {
          this.isChatLoading.set(false);
        }, 1000);
        setTimeout(() => {
          this.isHidden.set(false);
        }, 2500);
      })
    ).subscribe();
  }
}
